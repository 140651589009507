import { GetNotificationList } from "@/functions/BackendApiUrl";
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React, { useRef, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import useSWR from "swr";
import { Table } from "antd";
import Link from "next/link";
import { useSession } from "next-auth/react";

interface NotificationModalProps {
  show: boolean;
  onClose: () => void;
}
interface DataItem {
  id: string;
  message: string;
  note: string;
  name: string;
  source: string;
  createdAt: string;
}

interface DataItems {
  datas: DataItem[];
  totalData: number;
}

interface DataRow extends DataItem {
  rowNumber: number;
  id: string;
  key: React.Key;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  show,
  onClose,
}) => {
  const swrFetcher = useSwrFetcherWithAccessToken();
  const [search] = useState("");
  const [debouncedValue] = useDebounce(search, 1000);
  const [selectedDate] = useState<string>("");
  const [debouncedDate] = useDebounce(selectedDate, 500);
  const [page] = useState<number>(1);
  const { data: session } = useSession();
  const userId = session?.user?.["id"];

  const initialFilterData = {
    itemsPerPage: 5,
    page: page,
    search: "",
    date: "",
  };

  const [filterData] = useState(initialFilterData);

  const apiEndpoint = GetNotificationList(
    filterData.itemsPerPage,
    filterData.page,
    filterData.search,
    filterData.date,
    userId
  );

  const { data, isValidating } = useSWR<DataItems>(apiEndpoint, swrFetcher);

  const columns: ColumnsType<DataRow> = [
    {
      dataIndex: "message",
      key: "message",
      render: (text, row) => (
        <div>
          <div className="flex gap-1">
            {/* <div className="font-extrabold">{row.name} </div> */}
            <div>{text}</div>
            <div className="font-extrabold">{row.source}</div>
          </div>
          <div className="flex text-xs text-[#808080] mt-2">
            <p>
              {dayjs(row.createdAt).format("YYYY/MM/DD - HH:mm:ss")} |{" "}
              {row.source}
            </p>
          </div>
        </div>
      ),
    },
  ];

  function dataSource(): DataRow[] {
    if (!data?.datas) {
      return [];
    }

    return data.datas.map((item, index) => {
      const row: DataRow = {
        key: index,
        rowNumber: index + 1,
        id: item.id,
        name: item.name,
        message: item.message,
        note: item.note,
        source: item.source,
        createdAt: item.createdAt,
      };
      return row;
    });
  }

  const overviewData = dataSource();

  const filteredData = overviewData.filter((overview) => {
    const searchList = new RegExp(debouncedValue, "i");
    const matchesSearch = searchList.test(overview.message);
    const matchesDate = debouncedDate
      ? dayjs(overview.createdAt).isSame(debouncedDate, "day")
      : true;
    return matchesSearch || matchesDate;
  });

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose(); // Close modal if click occurs outside of it
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed z-[1000] inset-0 overflow-y-auto">
      <div className="flex items-start justify-end min-h-screen">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div
          ref={modalRef}
          className="bg-white z-50 rounded-lg overflow-hidden shadow-xl max-w-2xl mt-24"
        >
          <div className="p-4">
            <div className="flex w-full justify-between items-center mb-4">
              <div className="text-black font-bold">Notification</div>
              <Link
                href="./Notification/"
                className="text-blue-500 text-xs underline"
              >
                Tampilkan Semua Notifikasi
              </Link>
            </div>
            <div>
              <Table
                rowKey="id"
                dataSource={filteredData}
                columns={columns}
                loading={isValidating}
                pagination={false}
              />
            </div>
            <div className="flex justify-center items-center">
              <Link
                href="./Notification/"
                className="text-blue-500 text-xs underline"
              >
                Lihat Semua
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
