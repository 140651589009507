import React, { useState } from "react";
import { Title } from "../components/Title";
import { Page } from "../types/Page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faArrowRightFromBracket,
  faUserGear,
  faPeopleArrows,
  faPeopleGroup,
  faServer,
  faSigning,
  faInfoCircle,
  faWarning,
  faLock,
  faDatabase,
  faListCheck,
  faSignalPerfect,
  faBell, // Import the bell icon
} from "@fortawesome/free-solid-svg-icons";
import { signIn, signOut, useSession } from "next-auth/react";
import nProgress from "nprogress";
import { Authorize } from "@/components/Authorize";
import useSWR from "swr";
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import { BackendApiUrl } from "@/functions/BackendApiUrl";
import { useRouter } from "next/router";
import InformationModal from "@/components/InformationModal";
import NotificationModal from "./Notification/NotificationModal";

interface CategoryHomeApiModel {
  id: string;
  title: string;
  // description: string
}

const Home: React.FC = () => {
  const { data: session, status } = useSession();
  const [informationModal, setInformationModal] = useState<boolean>(false);
  const [category, setCategory] = useState<string>("");
  const displayUserName = session?.user?.name;
  const role = session?.user?.["role"][0];
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  const swrFetcher = useSwrFetcherWithAccessToken();

  const { data, isValidating } = useSWR<CategoryHomeApiModel[]>(
    BackendApiUrl.getCategories,
    swrFetcher
  );
  const ropaId = data?.find((item) => item.title.includes("ROPA"))?.id;

  const onClickCategory = (categoryId: string) => {
    if (categoryId == ropaId) {
      router.push(`/${categoryId}/RopaSelection`);
    } else {
      router.push(`/${categoryId}`);
    }
  };

  const handleIconModal = (categoryId: string) => {
    setInformationModal(true);
    setCategory(categoryId);
  };

  function handleCancel() {
    setInformationModal(false);
    router.push("/");
  }

  function getRelatedIcon(title: string): IconDefinition {
    title = title.toLowerCase();

    if (title.includes("ropa")) {
      return faServer;
    } else if (title.includes("pia33")) {
      return faPeopleArrows;
    } else if (title.includes("tria")) {
      return faPeopleGroup;
    } else if (title.includes("subject")) {
      return faDatabase;
    } else if (title.includes("incident")) {
      return faWarning;
    } else if (title.includes("policy")) {
      return faLock;
    } else if (title.includes("consent")) {
      return faListCheck;
    } else {
      return faSignalPerfect;
    }
  }

  if (isValidating) {
    nProgress.start();
  }

  return (
    <div>
      <nav className="flex items-center justify-between box-content w-full p-[10px] shadow-md bg-[#3788FD] fixed z-50">
        <div className="hidden lg:block">
          <img
            src="adaptist-white-logo.png"
            alt="logo"
            className="max-w-[200px] ml-[40px] cursor-pointer"
          />
        </div>
        <div className="flex items-center">
          {status === "authenticated" ? (
            <div
              className="hidden lg:block font-semibold text-[16px]"
            >
              Halo, {displayUserName}
            </div>
          ) : (
            <div></div>
          )}
          <div className="ml-8">
            <button onClick={() => setShowModal(true)}>
              <div className="px-2 py-1 ">
                <FontAwesomeIcon icon={faBell} size="lg" />
              </div>
            </button>
          </div>
          {role === "Admin" && (
            <div className="ml-8">
              <button onClick={() => router.push("/ManageUser")}>
                <div className="px-2 py-1">
                  <FontAwesomeIcon icon={faUserGear} size="lg"/>
                </div>
              </button>
            </div>
          )}
          {status === "authenticated" ? (
            <div className="pl-6">
              <button
                onClick={() => {
                  nProgress.start();
                  signOut({
                    callbackUrl: "/api/end-session",
                  });
                }}
              >
                <div className="px-2 py-1 mr-7">
                  <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" />
                </div>
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={() => {
                  nProgress.start();
                  signIn("oidc");
                }}
              >
                <div className="py-1 px-3 font-semibold border-[2px] border-white">
                  <FontAwesomeIcon icon={faSigning} size="lg"></FontAwesomeIcon> Login
                </div>
              </button>
            </div>
          )}
        </div>
      </nav>

      <div className="pt-[100px] pb-[10px]">
        <div
          className="justify-center text-center mt-4 font-bold text-black text-[42px]"
        >
          Sistem Evaluasi Kepatuhan UU No. 27 Tahun 2022 <br></br>
          (Perlindungan Data Pribadi)
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-12">
            {data?.map((Q, index) => (
              <React.Fragment key={"category#" + index}>
                {category && (
                  <InformationModal
                    onCancel={handleCancel}
                    categoryId={category}
                    visible={informationModal}
                  />
                )}
                <div className="col-span-12 lg:col-span-6 xl:col-span-4 2xl:col-span-3">
                  <div
                    className="cursor-pointer flex justify-center m-[5px]"
                  >
                    <div
                      className="rounded-md min-w-[400px] text-center text-white m-4 min-h-[180px] max-h-[180px] relative max-w-[400px] bg-[#3788FD] p-5
                                border-[1.5px] border-[#3788FD] border-solid transition-all duration-300 shadow-[0px_2px_4px_rgba(0,0,0,0.4)]"
                      onClick={() => onClickCategory(Q.id)}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = "white";
                        e.currentTarget.style.color = "#3788FD";
                        e.currentTarget.style.transform = "translateY(-8px)";
                        const infoIcon = e.currentTarget.querySelector(
                          ".info-icon"
                        ) as HTMLElement;
                        if (infoIcon) {
                          infoIcon.style.color = "#3788FD";
                        }
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = "#3788FD";
                        e.currentTarget.style.color = "white";
                        e.currentTarget.style.transform = "translateY(0)";
                        const infoIcon = e.currentTarget.querySelector(
                          ".info-icon"
                        ) as HTMLElement;
                        if (infoIcon) {
                          infoIcon.style.color = "white";
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        size="lg"
                        className="info-icon absolute top-[10px] right-[10px] transition-colors"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleIconModal(Q.id);
                        }}
                      />
                      <div className="categoryTitleHome">
                        <FontAwesomeIcon
                          icon={getRelatedIcon(Q.title)}
                          className="w-[50px] h-[50px]"
                        ></FontAwesomeIcon>
                        <br />
                        {Q.title}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <NotificationModal show={showModal} onClose={() => setShowModal(false)} />

      <footer className="mx-[24px] text-[14px] font-semibold fixed bottom-4 left-0 text-[#3788FD]">
        Copyright @ PT. Accelist Lentera Indonesia
      </footer>
    </div>
  );
};

const HomePage: Page = () => {
  return (
    <Authorize>
      <div
        className="text-white min-h-screen"
      >
        <Title>Home</Title>
        <Home></Home>
        <p className="invisible">V2.1.0</p>
      </div>
    </Authorize>
  );
};

export default HomePage;
